import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import {
    Popover,
    PopoverHandler,
    PopoverContent,
    Button,
} from "@material-tailwind/react";
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router';
import { toast, ToastContainer } from 'react-toastify';
import { ReactPaginateComponent, handleUnAuthorized } from '../hook/handleUnauthorized';


function UserBank() {
    const [bank, setBank] = useState([])
    const navigate = useNavigate()
    const location = useLocation()
    const userId = location?.state?.userId;
        const getUserBank= async (userId)=>{
                   try{
            const adminToken = localStorage.getItem('adminToken') || '';
            console.log(adminToken, 'userId', userId);
            const data = await axios.get(`${process.env.REACT_APP_API_URL}/admin/get-user-bank?userId=${location?.state?.userId}`, { headers: { Authorization: adminToken } });
            if (data.data.success) {
                setBank(data.data.data.bank)
            } else {
               console.log(data, '==error==');
                handleUnAuthorized(data.data.message, navigate)
            }
           }catch(error){
            console.log(error, "==error==");
           }
    }

    useEffect(()=> {
        getUserBank();
    },[])
    return (
        <>
            <div className="px-0 py-0 ">
                <div className="flex flex-no-wrap items-start">
                    <div className="w-full ">
                        <div className="py-4 px-2">
                            <div className="bg-white rounded shadow py-7">
                                <div className="mt-10 px-7">
                                        <p className="text-xl font-semibold leading-tight text-gray-800">
                                            Bank details of {bank?.userId?.name}
                                        </p>
                                        <div className="grid w-full grid-cols-1 lg:grid-cols-3 md:grid-cols-1 gap-7 mt-7 border p-3">
                                            <div>
                                                <p className="text-base font-medium leading-none text-gray-800">
                                                Bank Name
                                                </p>
                                                <input
                                                    className="w-full p-3 mt-4 border border-gray-300 rounded outline-none focus:bg-gray-50" value={bank?.bank_name || 'N/A'} readonly="true"/>
                                            </div>
                                            <div>
                                                <p className="text-base font-medium leading-none text-gray-800">
                                                Account Holder
                                                </p>
                                                <input
                                                    type="email"
                                                    className="w-full p-3 mt-4 border border-gray-300 rounded outline-none focus:bg-gray-50" value={bank?.account_holder || 'N/A'} readonly="true"/>
                                            </div>
                                            <div>
                                                <p className="text-base font-medium leading-none text-gray-800">
                                                  Account Number
                                                </p>
                                                <input
                                                    type="text"
                                                    className="w-full p-3 mt-4 border border-gray-300 rounded outline-none focus:bg-gray-50" value={bank?.account_number || 'N/A'} readonly="true"/>
                                            </div>
                                            <div>
                                                <p className="text-base font-medium leading-none text-gray-800">
                                                    IFSC Code 
                                                </p>
                                                <input
                                                    type="text"
                                                    className="w-full p-3 mt-4 border border-gray-300 rounded outline-none focus:bg-gray-50"  value={bank?.ifsc || 'N/A'} readonly="true" />
                                            </div>
                                            <div class="hidden lg:block">
                                            </div>
                                            <div className="flex flex-col mt-4 flex-wrap items-center justify-center w-full px-2 mb-3 lg:flex-row lg:justify-end md:justify-end gap-x-4 gap-y-4">
                                            <button
                                                type="button"
                                                onClick={() => navigate('/admin/all-withdraw')}
                                                style={{ height: '40px' }}
                                                className="bg-[#452a72] rounded  border border-[#452a72] transform duration-300 ease-in-out text-sm font-medium px-3 text-white  lg:max-w-[100px] w-full "
                                            >
                                                Back
                                            </button>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UserBank;
