import React, { useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';

export default function TinyMceEditor({pointSystemInstructions,handleProfile}) {

  const handleEditorChange = (newValue) => {
    handleProfile({ target: { name: 'pointSystemInstructions', value: newValue } });
  };

  return (
    <Editor
      apiKey='djiinbt7360gavhw2nldwp6izsnus9f9ozrboavy3cwb3kwu'
      value={pointSystemInstructions} // Use value prop to manage editor content
      init={{
        menubar: false, // Disable the menu bar
        plugins: [
          'undo', 'anchor', 'autolink', 'charmap', 'codesample', 'emoticons',
          'image', 'link', 'lists', 'media', 'searchreplace', 'table',
          'visualblocks', 'wordcount', 'textcolor', 'colorpicker',
          'checklist', 'mediaembed', 'casechange', 'export', 'formatpainter',
          'pageembed', 'a11ychecker', 'tinymcespellchecker', 'permanentpen',
          'powerpaste', 'advtable', 'advcode', 'editimage', 'advtemplate',
          'mentions', 'tableofcontents', 'footnotes',
          'mergetags', 'autocorrect', 'typography', 'inlinecss', 'markdown',
        ],
        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | forecolor backcolor | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
        tinycomments_mode: 'embedded',
        tinycomments_author: 'Author name',
        // images_upload_handler: handleImageUpload, // Set the upload handler
      }}
      onEditorChange={handleEditorChange} // Handle editor change
    />
  );
}
